/**
 * Prins: Components / Sortable
 * (Dependency: https://bevacqua.github.io/dragula/)
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import SelectorEngine from '../../../../shared/dom/selector-engine';
import EventHandler from '../../../../shared/dom/event-handler';
import Manipulator from '../../../../shared/dom/manipulator';

// -------
// Private
// -------

const NAME      = 'sortable';
const DATA_KEY  = `ifab.${NAME}`;
const EVENT_KEY = `.${DATA_KEY}`;
// const API_KEY   = '.data-api';

const DEFAULTS = {};

/**
 * ´Sortable handle´ für eine Tabellenzeile integrieren.
 *
 * @param {HTMLElement} container
 */
const addSortableHandleTable = (container) => {
	const rows    = SelectorEngine.children(container, 'tr');
	const wrapper = Manipulator.createElementFrom(`<div class="sortable-handle-wrapper">
		<div class="sortable-handle-wrapper__handle">
			<span aria-label="${window.Prins.tm.translate('sortRecord')}" class="handle handle-sortable" role="button">
				<i aria-hidden="true" data-icon class="icon fa-solid fa-grip-lines"/>
			</span>
		</div>
	</div>`);
	const content = Manipulator.createElementFrom('<div class="sortable-handle-wrapper__original"></div>');

	for (const row of rows) {
		const firstCell    = SelectorEngine.findOne('th:first-child, td:first-child', row);
		const cloneWrapper = wrapper.cloneNode(true);
		const cloneContent = content.cloneNode(true);

		Manipulator.copyContent(firstCell, cloneContent);

		firstCell.innerHTML = '';

		Manipulator.elementAppend(cloneContent, cloneWrapper);
		Manipulator.elementAppend(cloneWrapper, firstCell);
		Manipulator.addClass(firstCell, 'sortable-handle-added');
	}
};

/**
 * Elemente, auf BNasis eines ID-Arrays, umsortieren
 *
 * @param {HTMLElement}container
 * @param {HTMLElement}element
 * @param {Array}sort
 */
const rearrangeItems = (container, element, sort) => {
	if (sort.length > 0) {
		for (const id of sort) {
			const child = SelectorEngine.children(container, `[data-sort-id="${id}"], [data-id="${id}"]`);

			if (child[0]) {
				Manipulator.elementAppend(child[0], container);
			}
		}

		setTimeout(() => {
			const curElementId = element.dataset.sortId || element.dataset.id;

			let info = `Sorting of entry ${curElementId} subsequently adjusted!`;

			if (window.Prins.tm) {
				info = window.Prins.tm.translate(
					'sortItemRearranged',
					{
						id: curElementId
					}
				);
			}

			window.Prins.notificationInfo(info);
		}, 350);
	}
};

/**
 * Aktuelle Sortierung als ID-Array zurückgeben
 *
 * @param {HTMLElement} container
 * @returns {Array}
 */
const getCurrentSorting = (container) => {
	const childs = SelectorEngine.children(container, '*:not(.gu-mirror)');

	let sorting = [];

	for (const child of childs) {
		const childId = child.dataset.sortId || child.dataset.id;

		if (childId) {
			sorting.push(childId);
		}
	}

	return sorting;
};

/**
 * ´Sortable connected´-Container initialisieren.
 *
 * @param {HTMLElement} element
 * @returns {HTMLElement}
 */
const renderConnected = (element) => {};

/**
 * ´Sortable single´-Container initialisieren.
 *
 * @param {HTMLElement} container
 * @returns {HTMLElement}
 */
const renderSingle = (container) => {
	let useContainer = container;
	let childs       = SelectorEngine.children(useContainer, '*');

	const form         = SelectorEngine.parents(container, 'form')[0] ?? null;
	const xhr          = container.dataset.sortableXhr ?? null;
	const tag          = container.tagName.toLowerCase();
	const pluginParams = {
		mirrorContainer: useContainer,
		revertOnSpill  : true
	};

	// Allen Kindelementen ein entsprechendes Data-Attribut mitgeben.
	for (const child of childs) {
		child.dataset.sortable = '';
	}

	// Ist `control` ein Link und `uri` ist leer, dann hole URI aus dem Attribut `href`.
	if (tag === 'table') {
		const tbody = SelectorEngine.findOne('tbody', useContainer);

		if (tbody) {
			useContainer = tbody;
			childs       = SelectorEngine.children(useContainer, '*');
		}

		if (childs && childs.length > 1) {
			addSortableHandleTable(useContainer);

			pluginParams.mirrorContainer = useContainer;
			pluginParams.moves           = (el, container, handle) => {
				return handle.classList.contains('handle-sortable');
			};
		}
	} else {
		const sortableHandle = SelectorEngine.find('.handle-sortable', useContainer);

		if (sortableHandle.length > 0) {
			pluginParams.moves = (el, container, handle) => {
				return handle.classList.contains('handle-sortable');
			};
		}
	}

	// Bei nur einem Kindelement ist eine Sortierung sinnlos.
	if (childs && childs.length > 1) {
		dragula([useContainer], pluginParams)
			.on('cloned', function (clone) {
				const elements = SelectorEngine.find('*[id], *[for]', clone);

				for (const element of elements) {
					element.removeAttribute('id');
					element.removeAttribute('for');
				}
			})
			.on('drag', function (element) {
				Manipulator.removeClass(element, 'sortable-moved');
			})
			//.on('drop', function (element, target, source, sibling) {
			.on('drop', function (element, target) {
				const sorting = getCurrentSorting(target);

				Manipulator.addClass(element, 'sortable-moved');

				if (form) {
					EventHandler.trigger(form, 'changeDetected', {
						changeDetectedOn : element
					});
				}

				if (window.axios && xhr) {
					axios.post(xhr, {
						current: element.dataset.sortId || element.dataset.id,
						sorting: sorting
					})
						.then(function (response) {
							if (response.data && response.data.status === true) {
								if (response.data.sorting !== undefined && response.data.sorting.length > 0) {
									rearrangeItems(container, element, response.data.sorting);
								}

								if (window.Prins.notificationSuccess && (response.data.info !== undefined)) {
									window.Prins.notificationSuccess(response.data.info);
								}

								EventHandler.trigger(container, `sorted${EVENT_KEY}`, {
									currentSortable: {
										container: container,
										data     : ((response.data.sortable !== undefined && response.data.sortable.length > 0)  ? response.data.sortable : sorting),
										element  : element
									}
								});
							}
						})
						.catch(function (error) {
							throw new Error(error);
						});
				} else {
					EventHandler.trigger(container, `sorted${EVENT_KEY}`, {
						currentSortable: {
							container: container,
							data     : sorting,
							element  : element,
							rearrange: false
						}
					});
				}
			}).on('over', function (element, container) {
				Manipulator.addClass(container, 'sortable-over');
			}).on('out', function (element, container) {
				Manipulator.removeClass(container, 'sortable-over');
			});
	}
};

// ------
// Public
// ------

/**
 * ´Sort-, Filterlist´-Elemente zusammenstellen und initialisieren.
 *
 * @returns {Array}
 */
const init = () => {
	let group = [];

	const collection = SelectorEngine.find(`[data-provider="${NAME}"]`);

	if (collection.length) {
		if (window.dragula === undefined) {
			const msg = 'The dependency ´dragula.js´ cannot be loaded/initialized';

			if (window.Prins.error) {
				window.Prins.error.show({
					msg: msg
				});
			} else {
				throw new Error(msg);
			}
		} else {
			for (const element of collection) {
				const connected = Manipulator.getDataAttribute(element, `${NAME}-connected`);

				if (connected === 'single') {
					group.push(renderConnected(element));
				} else {
					group.push(renderSingle(element));
				}
			}
		}
	}

	return group;
};

// Export
export default {
	init: init
};
