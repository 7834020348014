/**
 * Prins: Components / Record group
 * (Dependency: https://bevacqua.github.io/dragula/)
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {noop} from '../../../../shared/utils/index';

import SelectorEngine from '../../../../shared/dom/selector-engine';
import Manipulator from '../../../../shared/dom/manipulator';
import EventHandler from '../../../../shared/dom/event-handler';
import Data from '../../../../shared/dom/data';

import BaseClass from '../../../../shared/utils/base-class';
import MultiActions from '../multi-actions';

/**
 * @type {string}
 */
const NAME = 'record-group';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	removable               : false,
	preview                 : {
		alt          : 'Preview',
		iconUndefined: '<i aria-hidden="true" class="fa-solid fa-file"></i>'
	},
	add                     : {
		label: 'Select file',
		text : 'Select file',
		icon : '<i aria-hidden="true" class="fa-solid fa-upload"></i>'
	},
	remove                  : {
		label: 'Remove file',
		text : 'Remove file',
		icon : '<i aria-hidden="true" class="fa-solid fa-trash"></i>'
	},
	selectableFullyClickable: true,
	onSelect                : noop,
	onRemove                : noop,
	onNoFileReader          : noop
};

/**
 *  Class
 */
class RecordGroup extends BaseClass {
	/**
	 * @param {HTMLElement|Node} [element=null]
	 * @param {Object} [config={}]
	 */
	constructor(element = null, config = {}) {
		if (!element) {
			return;
		}

		// Ist Element schon eine Instanz von `Nav`?
		const testInst = RecordGroup.getInstance(element);

		if (testInst && testInst['_config'] !== undefined) {
			return testInst;
		}

		super(element, config);

		this._mode              = '';
		this._id                = this._element.getAttribute('id') || null;
		this._childs            = this.childs = SelectorEngine.children(this._element, '*');
		this._selectableEnabled = false;
		this._selectedChilds    = 0;

		this._render();
	}

	/**
	 * API-methode ´selectAll()´.
	 */
	selectAll(flag = true) {
		for (const child of this._childs) {
			const evName = flag ? 'select' : 'unselect';

			EventHandler.trigger(child, `${evName}.${this.constructor.EVENT_KEY}`);
		}

		if (flag && this._childs.length > 0) {
			this.apiMultiActions.menuReady(true);
		} else {
			this.apiMultiActions.menuReady(false);
		}
	}

	/**
	 * API-methode ´unselectAll()´.
	 */
	unselectAll() {
		this.selectAll(false);
	}

	/**
	 * API-methode ´getSelectedIds()´.
	 */
	getSelectedData() {
		const childsSelected = this._childs.filter(child => child.dataset.selected !== undefined && child.dataset.selected === 'true');

		return childsSelected.map(child => {
			return child.dataset.id;
		});
	}

	// Private ---------------------------------------------------------------------------------------------------------

	/**
	 * Initialisierung.
	 *
	 * @private
	 */
	_render() {
		const lsGet = localStorage.getItem(`${window.Prins.prefix}${this.constructor.NAME}`);

		this._ls = lsGet ? JSON.parse(lsGet) : {
			selectable: []
		};

		const dropdownMultiActions = (this._id) ? SelectorEngine.findOne(`[data-connected="#${this._id}"]`) : null;

		if (dropdownMultiActions) {
			this.apiMultiActions = new MultiActions(dropdownMultiActions, {
				connectedApi: this,
				onEnable    : () => {
					this._enableSelectable();
					this._lsUpdate();
				},
				onDisable   : () => {
					this._disableSelectable();
					this._lsUpdate();
				},
				onStartRequest   : () => {
					this._element.dataset.loading = '';
				},
				onFinishRequest   : (data) => {
					// Kommt ein Reload-Parameter mit, dann:
					// - deaktiviere ´Selectable´
					// - aktualisiere ´Local storage´
					// - springe zur URL (Reload-Parameter)
					if (data.reload && data.reload !== '') {
						this._disableSelectable();
						this._lsUpdate();

						setTimeout(() => {
							window.location.replace(data.reload);
						}, 250);
					} else {
						this.unselectAll();

						delete this._element.dataset.loading;
					}
				}
			});

			// if (this._element.dataset.selectable !== undefined) {
			// 	this._enableSelectable();
			// }

			if (this._ls.selectable.includes(this._id)) {
				this.apiMultiActions.enable();
			}
		}
	}

	/**
	 * API-methode ´enableSelectable()´.
	 */
	_enableSelectable() {
		this._selectableEnabled = true;

		this._element.dataset.selectable = '';

		for (const child of this._childs) {
			child.dataset.selectable = '';

			EventHandler.on(child, `select.${this.constructor.EVENT_KEY}`, (event) => {
				const handle = SelectorEngine.findOne('[data-handle-selectable]', event.target);

				Manipulator.setAria(handle, 'checked', true);

				event.target.dataset.selected = 'true';

				this._selectedChilds++;

				if (this._selectedChilds > 0) {
					this.apiMultiActions.dropdownReady(true);
				}
			});

			EventHandler.on(child, `unselect.${this.constructor.EVENT_KEY}`, (event) => {
				const handle = SelectorEngine.findOne('[data-handle-selectable]', event.target);

				this._selectedChilds--;

				Manipulator.setAria(handle, 'checked', false);

				event.target.dataset.selected = 'false';

				if (this._selectedChilds <= 0) {
					this.apiMultiActions.dropdownReady(false);

					this._selectedChilds = 0;
				}
			});

			EventHandler.on(child, `checkSelect.${this.constructor.EVENT_KEY}`, (event) => {
				if (event.target.dataset.selected !== undefined && event.target.dataset.selected === 'true') {
					EventHandler.trigger(child, `unselect.${this.constructor.EVENT_KEY}`);
				} else {
					EventHandler.trigger(child, `select.${this.constructor.EVENT_KEY}`);
				}
			});

			//
			// Fokussierbare Elemente nicht fokussierbar machen!
			//

			let focusableElements = Data.get(child, 'focusableElements');

			if (!focusableElements) {
				// Fokussierbare Elemente deaktivieren.
				focusableElements = SelectorEngine.focusableChildren(child);

				Data.set(child, 'focusableElements', focusableElements);
			}

			for (const focusEl of focusableElements) {
				if (focusEl.dataset.handleSelectable === undefined) {
					Manipulator.makeNotFocusable(focusEl);
				}
			}

			//
			// Element vollflächig klickbar?
			//

			if (this._config.selectableFullyClickable) {
				Manipulator.addClass(child, '-fully-clickable');

				// Klick auf Element.
				EventHandler.on(child, `click.${this.constructor.EVENT_KEY}`, (event) => {
					EventHandler.trigger(child, `checkSelect.${this.constructor.EVENT_KEY}`);
				});
			} else {
				// Klick auf ´selectable handle´
				EventHandler.on(child, `click.${this.constructor.EVENT_KEY}`, '[data-handle-selectable]', (event) => {
					EventHandler.trigger(child, `checkSelect.${this.constructor.EVENT_KEY}`);
				});
			}
		}
	}

	/**
	 * API-methode ´disableSelectable()´.
	 */
	_disableSelectable() {
		this._selectableEnabled = false;

		delete this._element.dataset.selectable;

		for (const child of this._childs) {
			// Element deselektieren.
			EventHandler.trigger(child, `unselect.${this.constructor.EVENT_KEY}`);

			// Events entfernen.
			EventHandler.off(child, `select.${this.constructor.EVENT_KEY}`);
			EventHandler.off(child, `unselect.${this.constructor.EVENT_KEY}`);
			EventHandler.off(child, `checkSelect.${this.constructor.EVENT_KEY}`);
			EventHandler.off(child, `click.${this.constructor.EVENT_KEY}`);

			// Attribut, CSS-Klasse zurücksezten.
			delete child.dataset.selectable;

			Manipulator.removeClass(child, '-fully-clickable');

			//
			// Fokussierbare Elemente wieder fokussierbar machen!
			//

			const focusableElements = Data.get(child, 'focusableElements');

			if (focusableElements) {
				for (const focusEl of focusableElements) {
					if (focusEl.dataset.handleSelectable === undefined) {
						Manipulator.makeFocusable(focusEl);
					}
				}
			}
		}
	}

	/**
	 * Update ´Local storage´
	 *
	 * @private
	 */
	_lsUpdate() {
		// Update ´selectable´
		if (this._selectableEnabled) {
			if (!this._ls.selectable.includes(this._id)) {
				this._ls.selectable.push(this._id);
			}
		} else {
			this._ls.selectable = this._ls.selectable.filter(id => {
				return id !== this._id;
			});
		}

		// Im ´Local storage´ speichern.
		localStorage.setItem(`${window.Prins.prefix}${this.constructor.NAME}`, JSON.stringify(this._ls));
	}

	// Static ------------------------------------------------------------------

	// Getters -----------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

export const init = () => {
	const collection = SelectorEngine.find(`[data-${NAME}]`);

	let group = [];

	for (const element of collection) {
		group.push(new RecordGroup(element));
	}

	return group;
};

// Export
export default RecordGroup;
