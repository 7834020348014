/**
 * Prins: Components / Custom > Snippets
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import SelectorEngine from '../../../shared/dom/selector-engine';
import EventHandler from '../../../shared/dom/event-handler';

import {scrollTop} from '../../../shared/utils/scroll';

// -------
// Private
// -------

const driverHighlightSingle = () => {
	if (window.driver !== undefined && window.driver.js.driver !== undefined) {
		setTimeout(() => {
			const target = SelectorEngine.findOne('[data-provider="driver"]:not([data-driver-tour])');

			if (target) {
				// Scroll to element.
				// scrollTop(target).then(element => {
				// Highlight element visually.
				const instDriver = window.driver.js.driver({
					allowClose  : true,
					smoothScroll: true,
					stagePadding: 0,
					stageRadius : 0
				});

				instDriver.highlight({
					element: target
				});

				setTimeout(() => {
					if (instDriver.isActive()) {
						instDriver.destroy();
					}
				}, 1500);
				// });
			}
		}, 250);
	}
};

// ------
// Public
// ------

const snippets = () => {
	//
	// Tooltip initialisieren
	//

	const collectionTooltips = SelectorEngine.find('[data-bs-toggle="tooltip"]');
	const activeTooltips     = [...collectionTooltips].map(el => new bootstrap.Tooltip(el, {
		position: 'auto'
	}));

	//
	// Copyable
	//

	const collectionCopyable = SelectorEngine.find('[data-copyable]');

	[...collectionCopyable].map(element => {
		EventHandler.on(element, 'click.copyable', (event) => {
			// event.target.select();
			// event.target.setSelectionRange(0, 99999); // For mobile devices

			// Copy the text inside the text field
			navigator.clipboard.writeText(event.target.textContent );

			if (window.Prins.notificationInfo) {
				const info = (window.Prins.tm) ? window.Prins.tm.translate('textCopied') : 'Text%s copied';

				window.Prins.notificationInfo(info.replace(new RegExp('%s', 'gm'), ` &quot;${event.target.textContent}&quot;`));
			}

			// alert("Copied the text: " +  );
		});
	});

	//
	// Line clamp switchable
	//

	const collectionLineClamp = SelectorEngine.find('[data-line-clamp-switchable]');

	[...collectionLineClamp].map(element => {
		// Nur aufklappen.
		EventHandler.one(element, 'click.lineClampSwitchable', (event) => {
			event.target.classList.remove('line-clamp');
			delete event.target.dataset.lineClampSwitchable;
		});

		// Umschaltbar.
		// EventHandler.on(element, 'click', (event) => {
		//
		// 	event.target.classList.remove('line-clamp');
		// 	delete event.target.dataset.lineClampSwitchable;
		// 	const lcs = event.target.dataset.lineClampSwitchable;
		//
		// 	if (lcs === undefined || lcs === 'false') {
		// 		event.target.classList.add('line-clamp');
		// 		event.target.dataset.lineClampSwitchable = 'true';
		// 	} else {
		// 		event.target.classList.remove('line-clamp');
		// 		event.target.dataset.lineClampSwitchable = 'false';
		// 	}
		// });
	});

	driverHighlightSingle();
};

// Export
export default snippets;
