/**
 * Prins: Components / Multi actions
 * (Dependency: https://bevacqua.github.io/dragula/)
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import SelectorEngine from '../../../../shared/dom/selector-engine';
import Manipulator from '../../../../shared/dom/manipulator';
import EventHandler from '../../../../shared/dom/event-handler';

import BaseClass from '../../../../shared/utils/base-class';
import {noop} from "../../../../shared/utils";


/**
 * @type {string}
 */
const NAME = 'multi-actions';

/**
 * @type {string}
 */
const VERSION = '1.0.0';

/**
 *
 * @type {Object}
 */
const DEFAULT = {
	connectedApi   : null,
	onEnable       : noop,
	onDisable      : noop,
	onStartRequest : noop,
	onFinishRequest: noop
};

/**
 *  Class
 */
class MultiActions extends BaseClass {
	/**
	 * @param {HTMLElement|Node} [element=null]
	 * @param {Object} [config={}]
	 */
	constructor(element = null, config = {}) {
		if (!element) {
			return;
		}

		// Ist Element schon eine Instanz von `Nav`?
		const testInst = MultiActions.getInstance(element);

		if (testInst && testInst['_config'] !== undefined) {
			return testInst;
		}

		super(element, config);

		this._isDisabled = false;

		this._setup();
	}

	dropdownReady(flag = false) {
		if (flag) {
			Manipulator.setDisabled(this._dropdownToggle, false);
		} else {
			Manipulator.setDisabled(this._dropdownToggle);
		}
	}

	enable() {
		Manipulator.setAria(this._toggle, 'pressed', true);

		this._element.dataset.enabled = '';

		this._config.onEnable();
	}

	disable() {
		Manipulator.setAria(this._toggle, 'pressed', false);

		delete this._element.dataset.enabled;

		this._config.onDisable();
	}

	// Private ---------------------------------------------------------------------------------------------------------

	_setup() {
		this._toggle         = SelectorEngine.findOne(`.${this.constructor.NAME}__toggle`, this._element);
		this._dropdownMenu   = SelectorEngine.findOne(`.${this.constructor.NAME}__dropdown .dropdown-menu`, this._element);
		this._dropdownToggle = SelectorEngine.findOne(`.${this.constructor.NAME}__dropdown [data-bs-toggle="dropdown"]`, this._element);
		this._xhr            = this._element.dataset.xhr ?? null;

		if (!this._config.connectedApi || !this._xhr) {
			this._isDisabled = true;
			this._disable();
		} else {
			this._render();
		}
	}

	/**
	 * Initialisierung.
	 *
	 * @private
	 */
	_render() {
		EventHandler.on(this._toggle, `click.${this.constructor.EVENT_KEY}`, (event) => {
			const flag = Manipulator.getAria(event.target, 'pressed');

			event.preventDefault();

			if (flag) {
				this.disable();
			} else {
				this.enable();
			}
		});

		EventHandler.on(this._dropdownMenu, `click.${this.constructor.EVENT_KEY}`, 'button[data-cmd]', (event) => {
			 this._doRequest(event);
		});

		const connectedSelectedData = this._config.connectedApi.getSelectedData();

		if (connectedSelectedData.length > 0) {
			this.dropdownReady();
		} else {
			this.dropdownReady(false);
		}
	}

	_doRequest(event) {
		// eslint-disable-next-line unicorn/no-this-assignment
		const self = this;

		this._requestStarted();

		axios.post(this._xhr, {
			cmd : event.target.dataset.cmd,
			data: this._config.connectedApi.getSelectedData()
		})
			.then(function (response) {
				if (response.data.status === true) {
					self._requestFinished(response.data);
				}
			})
			.catch(function (error) {
				self._requestFinished(null);

				throw new Error(error);
			});
	}

	_requestStarted() {
		// this._toggleDropdown.dataset.loading = '';

		this._config.onStartRequest();
	}

	_requestFinished(data) {
		// delete this._toggleDropdown.dataset.loading;

		this._config.onFinishRequest(data);
	}

	_disable() {
		// Deaktiviere Toggle.
		Manipulator.setDisabled(this._toggle);

		// Deaktiviere Element.
		Manipulator.setDisabled(this._element);

		//
		// Meldungen zusammenstellen
		//

		let errors = [];

		if (!this._config.connectedApi) {
			errors.push("MultiActions requires option ´connectedApi´ with public ´getSelectedData´ function.");
		}

		if (!this._xhr) {
			errors.push("MultiActions (element) has no valid attribute ´data-xhr´.");
		}

		if (window.Prins.error) {
			window.Prins.error.show({
				msg: errors.join('<br/>')
			});
		} else {
			// eslint-disable-next-line unicorn/error-message
			throw new Error(errors.join(' '));
		}
	}

	// Static ----------------------------------------------------------------------------------------------------------

	// Getters ---------------------------------------------------------------------------------------------------------

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get VERSION() {
		return VERSION;
	}

	/**
	 * @returns {Object}
	 * @constructor
	 */
	static get Default() {
		return DEFAULT;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get NAME() {
		return NAME;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get DATA_KEY() {
		return `ifab.${this.NAME}`;
	}

	/**
	 * @returns {string}
	 * @constructor
	 */
	static get EVENT_KEY() {
		return `.${this.DATA_KEY}`;
	}
}

// Export
export default MultiActions;
