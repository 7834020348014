/**
 * Shared: Components > Button
 *
 * @copyright 2023 i-fabrik GmbH
 * @author Heiko Pfefferkorn
 */

import {extend} from '../../utils/index';
import {
	isElement,
	isString
} from '../../utils/is';

import SelectorEngine from '../../dom/selector-engine';
import Data           from '../../dom/data';
import Manipulator    from '../../dom/manipulator';

import Spinner from '../spinner';

// -------
// Private
// -------

const NAME     = 'button';
const DATA_KEY = `ifab.${NAME}`;
// const EVENT_KEY  = `.${DATA_KEY}`;
// const API_KEY    = '.data-api';

const DEFAULTS = {
	container: null,
	caret    : '<svg viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="6 9 12 15 18 9"></polyline></svg>'
};

const handleLoading = (element) => {
	// const _o = Data.get(element, `${DATA_KEY}.options`) || DEFAULTS;

	if (Manipulator.hasDataAttribute(element, 'loading')) {
		Spinner.addTo(element);
	} else {
		Spinner.removeFrom(element);
	}
};

const handleCaret = (element) => {
	const _o = Data.get(element, `${DATA_KEY}.options`) || DEFAULTS;

	if (Manipulator.hasDataAttribute(element, 'caret') && !SelectorEngine.findOne(`.${NAME}__caret`, element)) {
		const caret = Manipulator.createElementFrom(`<span class="${NAME}__caret">${_o.caret}</span>`);

		Manipulator.elementAppend(caret, element);
	} else {
		const caret = SelectorEngine.find(`.${NAME}__caret`, element);

		for (const element of caret) {
			element.remove();
		}
	}
};

/**
 * Monitor button changes.
 *
 * @type {MutationObserver}
 */
const observer = new MutationObserver((mutations) => {
	for (const mutation of mutations) {
		if (mutation.type === 'attributes') {
			switch (mutation.attributeName) {
				case 'data-loading': {
					handleLoading(mutation.target);
					break;
				}

				case 'data-caret': {
					handleCaret(mutation.target);
					break;
				}
			}
		}
	}
});

/**
 * ´Button´-Element initialisieren.
 *
 * @param {HTMLElement} element
 * @param {Object} o
 * @returns {HTMLElement}
 */
const render = (element, o) => {
	// Wurde Element schon initialisiert?
	if (Data.get(element, `${DATA_KEY}.initialized`)) {
		return element;
	}

	Data.set(element, `${DATA_KEY}.options`, o);

	handleLoading(element);
	handleCaret(element);

	observer.observe(element, {
		attributes: true
	});

	// Initialisierungsstatus setzen.
	Data.set(element, `${DATA_KEY}.initialized`, true);

	return element;
};

// ------
// Public
// ------

/**
 * ´Button´-Elemente zusammenstellen und initialisieren.
 *
 * @param {HTMLElement|String|null} [m=null]
 * @param {Object} [o={}]
 * @returns {HTMLElement|Array}
 */
const init = (m = null, o = {}) => {
	const _o = extend({}, DEFAULTS, o);

	let group;

	if (isElement(m)) {
		group = render(m, _o);
	} else {
		const collection = SelectorEngine.find(
			(isString(m)) ? m : `.${NAME}, .icon-${NAME}`,
			_o.container || document.documentElement
		);

		group = [];

		for (const element of collection) {
			group.push(render(element, _o));
		}
	}

	return group;
};

// Export
export default {
	init  : init
};
